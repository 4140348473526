<template>
  <component :is="layout">
    <template v-slot:title>Account Settings</template>
    <div class="p-0 p-md-3 bg-gray-light-4 overflow-auto h-100">
      <div class="row">
        <!-- Cards -->
        <div class="col-12 col-md-8 offset-md-2">
          <GoBackBtn :url="{ name: 'Dashboard' }">Back</GoBackBtn>
          <AppCard class="mb-4">
            <div class="p-3">
              <h2
                class="
                  m-0
                  mb-3
                  text-uppercase text-headline-5 text-md-headline-2
                "
              >
                Change Email Address
              </h2>
              <AppRowInputContainer label="Email">
                <label class="col-form-label py-0">{{ form.email }}</label>
              </AppRowInputContainer>
              <AppRowInputContainer label="New Email">
                <AppBaseInput value="" />
              </AppRowInputContainer>

              <AppBtn
                class="mr-0 mt-4 ml-auto d-block"
                :loading="false"
                @click="() => {}"
              >
                Confirm
              </AppBtn>
            </div>
          </AppCard>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import AppCard from '@/shared/elements/AppCard.vue';
import AppBaseInput from '@/shared/elements/AppBaseInput.vue';
import AppRowInputContainer from '@/shared/elements/AppRowInputContainer.vue';
import AppBtn from '../../../shared/elements/AppBtn.vue';
import PersonaConstants from '@/shared/constants/PersonaConstants';
import * as apiUser from '@/shared/api/Users';
import { mapMutations, mapGetters } from 'vuex';
import { merge } from 'lodash';

const DEFAULT_FORM = () => ({
  first_name: '',
  last_name: '',
  email: '',
  mobile_number: '',
  company_name: '',
  company_address: '',
  company_city: '',
  company_state: '',
  company_zip_code: '',
  profile_picture: '',
  get_order_update_text: false,
  get_order_update_email: false,
  get_marketing_update_text: false,
  get_marketing_update_email: false,
});

export default {
  components: {
    GoBackBtn,
    AppCard,
    AppBaseInput,
    AppBtn,
    AppRowInputContainer,
    LayoutSuper: () =>
      import('@/components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin'),
    LayoutVendor: () =>
      import('@/components/Partial/Layouts/LayoutVendor/LayoutVendor'),
    LayoutBuyer: () =>
      import('@/components/Partial/Layouts/LayoutBuyer/LayoutBuyer'),
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    layout() {
      const { persona } = this.user || '';
      switch (persona) {
        case PersonaConstants.super.value:
          return 'LayoutSuper';
        case PersonaConstants.vendor.value:
          return 'LayoutVendor';
        case PersonaConstants.buyer.value:
        default:
          return 'LayoutBuyer';
      }
    },
  },

  data() {
    return {
      form: DEFAULT_FORM(),
      loading: false,
      showSuccessModal: false,
    };
  },

  mounted() {
    const user = this.user;
    this.form = {
      first_name: user?.information?.first_name,
      last_name: user?.information?.last_name,
      email: user.email,
      mobile_number: user.mobile_number,
      company_name: user?.information?.company_name,
      company_address: user?.information?.company_address,
      company_city: user?.information?.company_city,
      company_state: user?.information?.company_state,
      company_zip_code: user?.information?.company_zip_code,
      profile_picture: user?.information?.profile_picture,
      get_order_update_text: Boolean(user?.information?.get_order_update_text),
      get_order_update_email: Boolean(
        user?.information?.get_order_update_email
      ),
      get_marketing_update_text: Boolean(
        user?.information?.get_marketing_update_text
      ),
      get_marketing_update_email: Boolean(
        user?.information?.get_marketing_update_email
      ),
    };
  },

  methods: {
    ...mapMutations('auth', {
      setUser: 'SET_USER',
    }),
    async handleSave() {
      this.loading = true;
      const { id } = this.user;
      const {
        get_order_update_text,
        get_order_update_email,
        get_marketing_update_text,
        get_marketing_update_email,
        ...form
      } = this.form;
      const updateResponse = await apiUser.updateUser(id, {
        _method: 'PUT',
        ...form,
        get_order_update_text: Number(get_order_update_text),
        get_order_update_email: Number(get_order_update_email),
        get_marketing_update_text: Number(get_marketing_update_text),
        get_marketing_update_email: Number(get_marketing_update_email),
      });

      if (updateResponse.status === 200) {
        const userResponse = await apiUser.getUser(id);
        this.setUser(merge(this.user, userResponse.data));
      }
      this.showSuccessModal = true;
      this.loading = false;
    },
  },
};
</script>
