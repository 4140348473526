<template>
    <input
        class="form-control"
        :value="value"
        @input="$emit('update', $event.target.value)"
        v-bind="$attrs"
        v-on="$listeners"
    />
</template>

<script>
export default {
model: {
    event: "update"
  },
  props: {
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: [String, Number, Boolean],
      default: ""
    }
  }
}
</script>
